<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          อำเภอ
        </h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form :method.sync="method" :options="options">

      <div>
        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12 col-sm-6"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
            ]">
          </sgv-input-text>

          <DetailDistrictProvinceInput
            :disabled="$auth.disabled(method)"
            :templateType="templateType"
            :countryType="countryType"
            class="col-12 col-sm-6"
            label="จังหวัด"
            v-model="formData.provinceId"
            select="id"
            :validations="[
              {text: 'required!', value: $v.formData.provinceId.$dirty && $v.formData.provinceId.$error}
            ]">
          </DetailDistrictProvinceInput>
        </div>
      </div>

    </sgv-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailDistrictProvinceInput from './DetailDistrictProvinceInput'
import {
  DETAIL_DISTRICT,
  CREATE_DISTRICT,
  UPDATE_DISTRICT,
  DESTROY_DISTRICT
} from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    countryType: {
      type: String,
      required: true
    },
    districtId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      listView: `Country${this.$form.capitalize(this.countryType)}List`,
      formData: {
        name: '',
        provinceId: null
      },
      method: 'info',
    }
  },
  validations: {
    formData: {
      name: { required },
      provinceId: { required },
    }
  },
  computed: {
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`country:${this.countryType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`country:${this.countryType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DISTRICT(this.templateType),
        variables: {
          countryType: this.countryType,
          districtId: this.districtId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.district)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return  {
        name: v.name,
        provinceId: v.provinceId,
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_DISTRICT(this.templateType),
        variables: {
          countryType: this.countryType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, districtId: res.data.createDistrict.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_DISTRICT(this.templateType),
        variables: {
          countryType: this.countryType,
          districtId: this.districtId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateDistrict)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DISTRICT(this.templateType),
        variables: {
          countryType: this.countryType,
          districtId: this.districtId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.listView) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.districtId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailDistrictProvinceInput
  }
}
</script>

<style lang="css" scoped>
</style>
